/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

  .md-drppicker .ranges ul li button{
      color: black !important;
  }
  .md-drppicker .ranges ul li button.active {
    color: white !important;   
  }
  .datepicker-input{
    padding: 10px;
    padding-right: 0%;
    background-color: white !important; 
    // width:70%;
  }
  @media (min-width:300px) and (max-device-width: 580px)  { 
      .datepicker-input{
          padding: 10px;
          padding-right: 0%;
          background-color: white !important; 
          width:75%;
      }
  }
  .tooltip {
    position: relative;
    display: inline-block;
    // border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color:var(--ion-color-dark);
    color: var(--ion-color-light);
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    // top: 150%;
    // left: 50%;
    // margin-left: -60px;
    top: -5px;
    right: 110%;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    // bottom: 100%;
    // left: 50%;
    top: 50%;
    left: 100%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
.teal{
    background-color: teal !important;
    color: #fff !important;
}
